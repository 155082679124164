import Vue from 'vue';
import 'whatwg-fetch';
import * as regexps from 'shared/validation_regexps';
import * as stripeValidationModule from 'shared/validation_stripe';
import * as constants from 'shared/constants';
import truncateFilter from 'filters/truncate';
import { valueAsCurrency, parseStringToNumber, formatNumber, toFloatOrZero } from 'shared/value_formats';
import SepaLineOnePopup from 'shared/components/sepa_line_one_popup';
import Teleport from 'vue2-teleport';

const BUYER_INFO_FIELDS = ['is_private_person', 'first_name', 'last_name', 'company_name', 'email', 'phone',
  'country_code', 'street', 'house_number', 'address_details', 'zipcode', 'city', 'state', 'vat_number'];

Vue.component('sepa_line_one_popup', SepaLineOnePopup);
Vue.component('teleport', Teleport);

export default {
  data() {
    return {
      card: null,
      windowApplePaySession: window.ApplePaySession,
      isNewCheckoutTheme: document.body.classList.contains('mod-new-checkout'),
      isOrderAccepted: false,
      agreeRefundPolicy: false,
      complete: false,
      stripeOptions: {
        style: {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
      },
      isGetPaymentButtonActive: false,
      isStripePaymentButtonAvailable: false,
      isStripePaymentButtonEnabled: false,
      stripeErrors: null,
      sepaErrors: null,
      isPrivate: true,
      checkoutWasClicked: false,
      showMoreProductDetails: true,
      submitIsDisabledValue: false,
      saleForbidden: false,
      saleabilityData: {},
      displaySaleabilityMessage: false,
      firstStepCheckoutErrors: {},
      ibanElement: null,
      paymentRequestButton: null,
      paymentRequest: null,
      isMobileShowMoreOpen: false,
      countryDialCode: '',
      checkVatTimeout: null,
      isRecalculating: false,
      previousGetPricingUrlParams: null,
      productDiscountGross: null,
      discountGross: null,
      discountNet: null,
      nextDiscountNet: null,
      nextDiscountGross: null,
      discountVatAmount: null,
      nextGrossAmount: null,
      nextShippingBruttoAmount: null,
      next: null,
      promocodeError: {},
      phone_code_origin: '',
      sepaLineOne: null,
      sepaLineOnePopupVisible: false,
      latestGetPricingDataTS: undefined,
      skipCountryCodeWatcher: false,
      window: {
        width: 0,
        height: 0
      },
    }
  },
  filters: {
    truncateFilter,
  },
  mounted: function () {
    var getPaymentButtonExists = document.getElementById('payment-request-button');
    if(getPaymentButtonExists){
      this.mountGetPaymentButton();
    }
    var cardElementExists = document.getElementById('card-element_');
    if(cardElementExists){
      this.mountStripe();
    }
    var ibanElementExists = document.getElementById('iban-element');
    if(ibanElementExists){
      this.mountIban();
    }
    if(this.isTwoStepCheckout()) {
      window.addEventListener('keyup', this.onKeyupTwoStepHandle);

      if(this.isNewCheckoutTheme) {
        $(window).on('resize', () => {
          this.updateNew2StepCheckoutProdDecription();
        });
        this.updateNew2StepCheckoutProdDecription();
      }
    }
    window.addEventListener('submit', this.submitCheckoutForm);
    window.addEventListener('beforeunload', this.showAlertOnClosing);
    this.enableScrollTracking();
  },
  created: function () {
    if(sessionStorage.getItem('firstStepOrderFormId') && this.isTwoStepCheckout()) {
      this.redirectToSecondStepCheckout();
    } else {
      // This is needed to delete info if checkout for product was updated from two-step to one-step
      sessionStorage.removeItem('productSlug');
      sessionStorage.removeItem('firstStepOrderFormId');
    }
    if(this.recentSalesInfoPresence) {
      this.recentSalesCarousel(0);
    }
    this.setPaypalVisibilityForAddons();
    this.initPromocodeFromParams();
    this.beforeGetPricingData();
    this.getSaleability();
    this.trackIfPrivatePerson();
    this.initVatNumber();
    this.setCheckedAddons();
    this.showAddressFieldsForInvoice();
    this.setPhoneCode(false);
    this.defineInitialIsInvoiceNeeded();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    window.__enableCheckout__ = this.enableCheckout;
  },
  beforeDestroy: function () {
    window.removeEventListener('keyup', this.onKeyupTwoStepHandle);
    window.removeEventListener('beforeunload', this.showAlertOnClosing);
  },
  computed: {
    digitalNoRefundAgreementText() {
      return this.orderForm.product.product_type == 'digital' &&
             this.isPrivatePerson &&
             !this.refundPossible
    },
    serviceWithDigitalContentNoRefundAgreementText() {
      return (this.orderForm.product.product_type == 'digital_service' || this.orderForm.product.product_type == 'physical_service') &&
             this.isPrivatePerson &&
             !this.refundPossible
    },
    eventNoRefundAgreementText() {
      return this.orderForm.product.product_type == 'event' &&
             this.isPrivatePerson &&
             !this.refundPossible
    },
    isPrivatePerson() {
      return this.orderForm.is_private_person == 'true'
    },
    addressRequired: function() {
      return (this.orderForm.is_invoice_needed ||
        this.isAddressRequired ||
        this.addons.some(addon => addon.isChecked &&
                         addon.is_shippable));
    },
    countryHasStates: function() {
      return !!this.statesInfo[this.orderForm.country_code];
    },
    stateNotEmpty: function() {
      return !!this.orderForm.state;
    },
    currentCountryStates: function() {
      return this.statesInfo[this.orderForm.country_code];
    },
    notZeroTotal: function() {
      return this.totalBruttoAmountPreview > 0;
    },
    zeroTotalAndNotTrial() {
      return this.totalBruttoAmount == 0 && (
          this.currentPaymentPlan.plan_type == constants.ONE_TIME_PAYMENT ||
          (this.currentPaymentPlan.plan_type != constants.ONE_TIME_PAYMENT && !this.isProductTrial));
    },
    invoiceWithoutAddress: function() {
      if (this.paymentMethod == constants.INVOICE) {
        if (!this.twoStepsCheckoutInfo.street || !this.twoStepsCheckoutInfo.house_number || !this.twoStepsCheckoutInfo.zipcode || !this.twoStepsCheckoutInfo.city) {
          return true;
        }
      } else {
        return false;
      }
    },
    addonsRequireAddress() {
      if (this.isSingleStepCheckout()) {
        return this.checkedAddons.filter(addon => addon.requires_address).length > 0;
      } else {
        return this.addons.filter(addon => addon.requires_address).length > 0;
      }
    },
    isCountrySwitzerland() {
      return (this.orderForm.country_code == constants.SWITZERLAND_COUNTRY_CODE);
    },
    summaryPaymentPlanTitle: function () {
      return this.currentPaymentPlan.plan_type == constants.ONE_TIME_PAYMENT ? this.currentPaymentPlan.name : `${this.currentPaymentPlan.name}, ${this.currentPaymentPlan.short_description}`
    },
    summaryPaymentPlanSubTitle: function () {
      return this.checkPlanDescription(this.currentPaymentPlan);
    },
    submitIsDisabled: function () {
      return (this.showRefundAgreement && !this.agreeRefundPolicy && !this.isOnFirstStep()) ||
          !this.paymentMethod || this.checkoutWasClicked ||
          this.submitIsDisabledValue || this.saleForbidden || this.notInStock;
    },
    nextStepIsDisabled: function () {
      return (this.showRefundAgreement && !this.agreeRefundPolicy && !this.isOnFirstStep()) ||
          this.checkoutWasClicked ||
          this.submitIsDisabledValue || this.saleForbidden || this.notInStock;
    },
    showRefundAgreement: function () {
      return (this.product.refund_possibility_days === 0 || this.checkedAddons.some(addon => addon.refund_possibility_days === 0))
        && !this.refundUnavailableForCompany;
    },
    refundUnavailableForCompany: function() {
      return !this.isPrivate && (
        constants.SERVICE_PRODUCT_TYPES.includes(this.product.product_type) ||
        this.checkedAddons.some(addon => constants.SERVICE_PRODUCT_TYPES.includes(addon.source_product_type))
      )
    },
    showAddress: function () {
      if(this.isAddressRequired || this.addonsRequireAddress) {
        return true;
      }
      return !(this.isPrivate && !this.orderForm.is_invoice_needed);
    },
    showPhone: function () {
      return this.isPhoneRequired || this.paymentMethod == constants.SEQURA;
    },
    bruttoPricePreview: function () {
      return this.isProductTrial ? this.orderForm.default_trial_price : this.productBruttoAmount;
    },
    productBruttoAmountWithDiscount: function () {
      return this.isProductTrial ? this.orderForm.default_trial_price : this.productBruttoAmount - this.productDiscountGross;
    },
    bruttoPriceWithDiscount: function () {
      return this.productBruttoAmount - this.discountGross;
    },
    nettoPricePreview: function () {
      if(this.isProductTrial) {
        return this.orderForm.default_trial_price;
      } else if(this.orderForm.has_net_price) {
        return this.productNettoAmount;
      } else {
        return this.withDiscount() ? (this.bruttoPrice / (1 + this.vatPercent / 100)).toFixed(2) : this.orderForm.netto_price;
      }
    },
    nettoPricePreviewWithDiscount: function () {
      return this.isProductTrial ? this.orderForm.default_trial_price : this.productNettoAmount - this.discountNet;
    },
    vatAmountPreview: function () {
      if(this.isProductTrial) {
        return this.orderForm.default_trial_price;
      } else if(this.orderForm.has_net_price) {
        return this.productVatAmount;
      } else {
        return this.withDiscount() ? this.bruttoPrice - this.nettoPricePreview: this.orderForm.vat_amount;
      }
    },
    vatAmountPreviewWithDiscount: function () {
      return this.isProductTrial ? this.orderForm.default_trial_price : this.productVatAmount - this.discountVatAmount;
    },
    showFirstStepForm: function () {
      return this.twoStepsCheckoutInfo.steps_completed == 0;
    },
    showSecondStepForm: function () {
      this.beforeGetPricingData();
      return this.twoStepsCheckoutInfo.steps_completed == 1;
    },
    refundPossible: function() {
      return this.product.refund_possibility_days > 0
    },
    showGuarantee: function() {
      return (this.productTemplate.show_guarantee === 'true' && this.refundPossible);
    },
    checkedAddons: function() {
      return this.addons.filter(addon => !!addon.isChecked);
    },
    hasAddonsUnavailableForPaypal: function() {
      if (this.checkedAddons.length === 0) { return false };

      let unavailableFromSettings = this.checkedAddons.filter(addon => addon.unavailable_for_paypal).length > 0;

      return unavailableFromSettings || this.recurringWithoutTrialPlanCountInOrder > 1 || this.trialPlanCountInOrder > 1 ||
        (this.recurringWithoutTrialPlanCountInOrder + this.trialPlanCountInOrder > 1);
    },
    recurringWithoutTrialPlanCountInOrder: function() {
      let count = 0;
      if (this.currentPaymentPlan.plan_type !== constants.ONE_TIME_PAYMENT && !this.product.is_trial) { count++ };
      return count + this.checkedAddons.filter(addon => {
        return addon.plan_type !== constants.ONE_TIME_PAYMENT && !addon.trial_days;
      }).length;
    },
    trialPlanCountInOrder: function() {
      let count = 0;
      if (this.product.is_trial) { count++ };
      return count + this.checkedAddons.filter(addon => addon.trial_days).length;
    },
    addonsTotalAmount: function() {
      return _.sum(_.map(this.checkedAddons, addon => parseFloat(addon.bruttoPrice)));
    },
    addonsTotalAmountPreview: function() {
      return _.sum(_.map(this.checkedAddons, addon => parseFloat(addon.bruttoPricePreview)));
    },
    addonsTotalDiscount: function() {
      return _.sum(_.map(this.checkedAddons, addon => parseFloat(addon.discountGross)));
    },
    addonsTotalDiscountPreview: function() {
      return _.sum(_.map(this.checkedAddons, addon => parseFloat(addon.discountGrossPreview)));
    },
    shippingNettoAmountPreview: function() {
      return parseFloat(this.shippingNettoAmount, 10);
    },
    productOldPrice: function() {
      return parseFloat(this.productTemplate.old_price.toString().replace(',', '.'), 10);
    },
    onlyNotTrialAddons: function() {
      return this.checkedAddons.filter(addon => !addon.trial_days).length === this.checkedAddons.length;
    },
    isShippableProduct: function() {
      return this.isProductShippable || this.checkedAddons.some(addon => addon.is_shippable);
    },
    showShipping: function() {
      return this.isShippableProduct && !this.isProductTrial;
    },
    hideSepaCardFields: function() {
      return !this.isSepaChecked() || !this.isSepaAvailable;
    },
    isSofortAvailable: function () {
      let addonsOneTimePayment = this.checkedAddons.filter(addon => addon.plan_type !== constants.ONE_TIME_PAYMENT).length === 0;
      let oneTimePaymentPlan = this.currentPaymentPlan.plan_type === constants.ONE_TIME_PAYMENT;
      let available = oneTimePaymentPlan && this.paymentMethods.sofort && addonsOneTimePayment;

      if (!available && this.isSofortChecked()) { this.paymentMethod = null; }

      return available;
    },
    isPaypalHiddenForCurrentPlan: function() {
      return (this.currentPaymentPlan.plan_type !== constants.ONE_TIME_PAYMENT && !!this.currentPaymentPlan.second_payment_in && !!this.product.is_trial) || this.currentPaymentPlan.frequency == 'biennial';
    },
    notInStock: function() {
      return this.inStockLeft != null && this.inStockLeft <= 0 && this.paymentMethod != constants.TEST;
    },
    hasInStockLimit: function() {
      return this.inStockLeft != null && Number.isInteger(Number(this.inStockLeft));
    },
    maxProductQty: function() {
      let maxProdQty = null;

      if(this.orderForm.max_products_quantity == null) {
        maxProdQty = this.inStockLeft != null && this.inStockLeft > 0 ? this.inStockLeft : null;
      } else {
        maxProdQty = this.inStockLeft != null && this.inStockLeft > 0 ? Math.min(this.orderForm.max_products_quantity, this.inStockLeft) : this.orderForm.max_products_quantity;
      }

      return maxProdQty;
    },
    showRecentSalesPopup: function() {
      return this.recentSalePopupVisible;
    },
    totalProductsPriceWithInstallmentFuturePayment() {
      let totalProductPrice = this.toFloatOrZero(
        this.isBreakdownPayment(this.currentPaymentPlan) ?
        this.totalBreakdown(this.currentPaymentPlan) :
        this.bruttoPriceWithDiscountPreview()
      );

      let totalAddonsPrice = _.sum(_.map(
        this.checkedAddons,
        (addon) => {
          let addonBruttoPriceSource = addon.discountGrossPreview ? 'bruttoPriceWithDiscount' : 'bruttoPricePreview';

          let addonPrice = this.isBreakdownPayment(addon) ?
            this.addonsTotalBreakdown(addon) :
            this.getAddonTotalValue(addon, addonBruttoPriceSource);

            return this.toFloatOrZero(addonPrice);
          }
        )
      );

      let totalPriceWithInstallments = totalProductPrice + totalAddonsPrice;
      return this.toFloatOrZero(this.totalBruttoAmountPreview) == totalPriceWithInstallments ? 0 : totalPriceWithInstallments;
    },
    recentSalesInfoPresence() {
      return this.recentSalesInfo && this.recentSalesInfo.length > 0;
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    redirectToSecondStepCheckout() {
      this.twoStepsCheckoutInfo = { product_two_steps_checkout: true, steps_completed: 1 };
      const productSlug = sessionStorage.getItem('productSlug');
      const firstStepOrderFormId = sessionStorage.getItem('firstStepOrderFormId');

      this.$http.get(`${this.redirectToSecondStepUrl.replace(':id', productSlug)}?form_id=${firstStepOrderFormId}`).then((response) => {
        this.skipCountryCodeWatcher = true;
        Vue.set(this, "twoStepsCheckoutInfo", JSON.parse(response.body.two_steps_checkout_info));
        Vue.set(this, "orderForm", JSON.parse(response.body.order_form));
        this.setPhoneCode(true);
        Vue.set(this, "phone_code_origin", this.orderForm.phone_code);

        sessionStorage.removeItem('productSlug');
        sessionStorage.removeItem('firstStepOrderFormId');
      })
    },
    defineInitialIsInvoiceNeeded() {
      this.orderForm.is_invoice_needed = true;
    },
    updateSepaLineOneValue(value) {
      this.sepaLineOne = value;
    },
    enableScrollTracking() {
      // sticky header tracking
      $(window).on('scroll', (e) => {
        if($(window).scrollTop() > 20) {
          $('body').addClass('header-detached');
        } else {
          $('body').removeClass('header-detached');
        }
      });
    },
    parseInternationalFloat: function(raw_str) {
      if(typeof raw_str === "number") {
        return raw_str;
      }

      return parseStringToNumber(raw_str);
    },
    closeRecentSalesPopup: function() {
      this.recentSalePopupVisible = false;
    },
    recentSalesCarousel: function(index) {
      if (this.recentSalesInfoPresence) {
        index = index || 0;
        let recentSaleSection = $('.recent-sale-section');

        if(Number(recentSaleSection.css('opacity')) === 0) {
          recentSaleSection.css('opacity', '1');
        }

        let recentSaleSectionBuyer = recentSaleSection.find('.recent-sale-section--buyer');
        let recentSaleItem = this.recentSalesInfo[index];
        recentSaleSectionBuyer.fadeOut(200);

        recentSaleSection.find('.flag').css('background-image', `url(https://s3.eu-central-1.amazonaws.com/public-prod-copecart.com/flags/4x3/${recentSaleItem.country.toLowerCase()}.png)`);
        recentSaleSection.find('.section_buyer').text(recentSaleItem.buyer);
        recentSaleSection.find('.section_bought_label').text(recentSaleItem.bought_label);
        recentSaleSection.find('.section_date').text(recentSaleItem.date);

        recentSaleSectionBuyer.fadeIn(300);

        window.setTimeout(
          this.recentSalesCarousel.bind(undefined, (index + 1) % this.recentSalesInfo.length),
          3000
        );
      }
    },
    updateNew2StepCheckoutProdDecription() {
      const descriptionSrollHeight = $('.dc-wizard--product-description span')[0].scrollHeight;
      const descriptionExpandableElements = $('.dc-wizard--product-description, .dc-wizard--product-description-expander');
      if(descriptionSrollHeight < 200) {
        descriptionExpandableElements.addClass('dc-expanded-permanently');
      } else {
        descriptionExpandableElements.removeClass('dc-expanded-permanently');
      }
    },
    getMaxAddonQuntity: function(addon) {
      let maxAddonQty = null;
      let addonHasInStockLimit = this.addonHasInStockLimit(addon);

      if(addon.max_quantity == null) {
        maxAddonQty = addonHasInStockLimit ? this.inStockLeft : null;
      } else {
        maxAddonQty = addonHasInStockLimit ? Math.min(addon.max_quantity, addon.inStockLeft) : addon.max_quantity;
      }

      return maxAddonQty;
    },
    addonHasInStockLimit: function(addon) {
      return addon.inStockLeft != null && Number.isInteger(Number(addon.inStockLeft));
    },
    addonInStockVisible: function(addon) {
      return addon.isStockVisible != null && !!addon.isStockVisible;
    },
    addonNotInStock: function(addon) {
      return addon.inStockLeft != null && addon.inStockLeft <= 0 && this.paymentMethod != constants.TEST;
    },
    generateCountryFlag(code) {
      if (code && code.length > 0) {
        return code.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397) );
      }
    },
    showCommonErrorModal(msg) {
      this.commonErrorModalMessage = msg;
    },
    closeCommonErrorModal() {
      this.commonErrorModalMessage = '';
    },
    addonError(addonId, errorName) {
      return this.orderFormErrors[`addons.${addonId}.${errorName}`];
    },
    closeSaleabilityMessage() {
      this.displaySaleabilityMessage = false;
      document.body.classList.remove('modal-open');
    },
    isOnFirstStep() {
      return this.twoStepsCheckoutInfo.product_two_steps_checkout && this.twoStepsCheckoutInfo.steps_completed === 0;
    },
    isOnSecondStep() {
      return this.twoStepsCheckoutInfo.product_two_steps_checkout && this.twoStepsCheckoutInfo.steps_completed === 1;
    },
    isTwoStepCheckout() {
      return this.twoStepsCheckoutInfo.product_two_steps_checkout;
    },
    openSaleabilityMessage() {
      this.displaySaleabilityMessage = true;
      document.body.classList.add('modal-open');
    },
    submitCheckoutForm(e){
      e.preventDefault();
      this.orderNow();
    },
    onKeyupTwoStepHandle(e) {
      let vm = this;
      if (e.keyCode === 13) {
        vm.handleCurrentStep();
      }
    },
    productQuantityValidation(max) {
      let maxQty = this.inStockLeft != null && this.inStockLeft > 0 ? Math.min(max, this.inStockLeft) : max;

      if(this.orderForm.products_quantity > maxQty || this.orderForm.products_quantity <= 0) {
        this.orderForm.products_quantity = 1;
      }
    },
    addonQuantityValidation(id, max) {
      let addon = this.normalAddons.find((adn) => adn.id == id);

      // we should not check the qty when addon not normal addon
      if(!addon) {
        return;
      }

      let addonHasInStockLimit = this.addonHasInStockLimit(addon);
      let addonMaxQty = null;

      // zero or empty string
      if(addon.quantity <= 0 || addon.quantity == "") {
        addon.quantity = 1;
      }

      // no max qty and unlimited stock
      if(max == null && !addonHasInStockLimit) {
        if(addon.quantity == null) {
          addon.quantity = 1;
        }
        return;
      }

      // max qty and unlimited stock
      if(max > 0 && !addonHasInStockLimit) {
        addon.quantity = Math.min(max, addon.quantity);
        return;
      }

      // no max qty and limited stock
      if(max == null && addonHasInStockLimit) {
        addonMaxQty = addon.inStockLeft;
      }

      // max qty and limited stock
      if(max > 0 && addonHasInStockLimit) {
        addonMaxQty = Math.min(max, addon.inStockLeft);
      }

      if(addon.quantity > addonMaxQty) {
        addon.quantity = addonMaxQty;
      }
    },
    handleCurrentStep() {
      let vm = this;
      if(vm.showFirstStepForm) {
        vm.handleFirstStepCheckout();
      } else if (vm.showSecondStepForm) {
        if (event.target.id == 'promocode') {
          vm.getAndSetPromocodeId()
        } else {
          vm.orderNow();
        }
      }
    },
    removeFirstStepCheckoutError: function(error_key){
      this.firstStepCheckoutErrors[`${error_key}`] = ''
    },
    mountGetPaymentButton: function() {
      if(!this.isStripeAvailable) {
        return;
      }

      let vm = this,
          elements = this.stripeProxy.elements;

      this.paymentRequest = this.stripe.paymentRequest({
        country: 'DE',
        currency: this.orderForm.currency.toLowerCase(),
        requestPayerEmail: true,
        requestPayerName: true,
        requestPayerPhone: true,
        total: {
          label: this.product.name,
          amount: parseInt((parseFloat(this.totalBruttoAmountPreview) * 100).toFixed(2))
        }
      });

      vm.paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest: vm.paymentRequest,
        style: {
          paymentRequestButton: {
            height: '50px'
          }
        }
      });

      vm.paymentRequest.canMakePayment().then(function(result) {
        if (result) {
          vm.isStripePaymentButtonEnabled = true;
          vm.isGetPaymentButtonActive = vm.notZeroTotal;
          vm.isStripePaymentButtonAvailable = vm.notZeroTotal;
          vm.paymentRequestButton.mount('#payment-request-button');
        } else {
          vm.isStripePaymentButtonEnabled = false;
          vm.isStripePaymentButtonAvailable = false;
          document.getElementById('payment-request-button-container').style.display = 'none';
        }
      });

      vm.paymentRequestButton.on('click', (e)=>{
        vm.paymentMethod = constants.STRIPE;
        if(vm.saleForbidden) {
          vm.openSaleabilityMessage();
          e.preventDefault();
        }
        if(vm.notInStock) {
          vm.showNotInStockModal();
          e.preventDefault();
        }
      });

      vm.paymentRequest.on('paymentmethod', function (ev) {
        let name = ev.paymentMethod.billing_details.name || (vm.orderForm.first_name + ' ' + vm.orderForm.last_name),
          email = ev.paymentMethod.billing_details.email || vm.orderForm.email,
          phone = ev.paymentMethod.billing_details.phone ||  vm.orderForm.phone || "",
          currency = vm.orderForm.currency.toLowerCase(),
          totalAmount = vm.totalBruttoAmountPreview;

        vm.isGetPaymentButtonActive = true;
        vm.checkoutWasClicked = true;

        //START: fill form from ev object
        if(!_.trim(vm.orderForm.first_name) || !_.trim(vm.orderForm.last_name)) {
          name = ev.paymentMethod.billing_details.name.split(" ");
          vm.orderForm.first_name = name[0];
          vm.orderForm.last_name = name[1];

          vm.fillFormFieldWith('first_name', name[0], true);
          vm.fillFormFieldWith('last_name', name[1], true);
        }

        // sanitize phone number
        phone = phone.replace(/[\(\)\\-\s]/g, "");
        phone = phone.replace(vm.orderForm.phone_code, '');
        phone = phone.replace('+', '');
        if(!regexps.PHONE_WITHOUT_CODE.test(phone)) {
          phone = "";
        }

        vm.fillFormFieldWith('phone', phone);
        vm.fillFormFieldWith('email', ev.paymentMethod.billing_details.email);

        if (vm.orderForm.is_invoice_needed ||
          vm.isAddressRequired ||
          vm.addons.some(addon => addon.isChecked &&
                         addon.is_shippable)) {

          vm.fillFormFieldWith('address', ev.paymentMethod.billing_details.address.line1);
          vm.fillFormFieldWith('zipcode', ev.paymentMethod.billing_details.address.postal_code);
          vm.fillFormFieldWith('city', ev.paymentMethod.billing_details.address.city);
        }

        if(!vm.orderForm.country_code && ev.paymentMethod.billing_details.address.country) {
          vm.orderForm.country_code = ev.paymentMethod.billing_details.address.country;
        }
        //END: fill form from ev object

        if(!vm.getPaymentButtonFormValidate()) {
          ev.complete('fail');
          vm.checkoutWasClicked = false;
          return false;
        }


        vm.$nextTick(() => {
          $('#addons_info').val(vm.getCheckedAddonsParams());
          var currentForm = document.querySelector('form#checkout_order_form');
          var formData = new FormData(currentForm);
          var order = {}

          for(let [key, value] of formData) {
            order[key] = value
          }

          vm.stripeProxy.confirmPaymentMethod({
            name: name,
            email: email,
            currency,
            paymentMethod: ev.paymentMethod,
            totalAmount: totalAmount,
            order: order
          },
          () => {
            vm.checkoutWasClicked = false;
            Vue.set(vm, 'submitIsDisabledValue', true);
            ev.complete('success');
            location.href = vm.setUrl()
          },
          (result) => {
            ev.complete('fail');
            vm.checkoutWasClicked = false;
            vm.stripeErrors = stripeValidationModule.stripeErrors(result);
          });
        });

      });
    },
    fillFormFieldWith: function(name, value, replace = false) {
      if(!_.trim(this.orderForm[name]) || !!replace) {
        this.orderForm[name] = value;

        $(`#${name}`).prev('.vfl-label').addClass('vfl-label-on-input');
      }
    },
    getPaymentButtonFormValidate: function() {
      let requiredFieldName = "is-refund-unavailable-section";

      $(`#is-refund-unavailable-section`).removeClass('mod-group-error');

      if(!this.formDataValid()) {
        Vue.set(this, 'submitIsDisabledValue', false);
        return false;
      }

      if (this.product.refund_possibility_days === 0 && !this.agreeRefundPolicy) {
        $(`#${requiredFieldName}`).addClass('mod-group-error').one('click', (e) => {
          $(e.currentTarget).removeClass('mod-group-error');
          Vue.set(this, 'submitIsDisabledValue', false);
        });
        $('html, body').animate({
          scrollTop: $(`#${requiredFieldName}`).offset().top - 100
        }, 250);
        return false;
      }
      return true;
    },
    getPaymentButtonFormSubmit: function () {
      this.paymentRequest.show();
    },
    mountStripe: function() {
      this.card = this.stripeProxy.elements.create('card');
      this.card.mount('#card-element_');
      this.card.on('change', (event) => {
        if (event.error) {
          this.stripeErrors = event.error.message;
        } else {
          this.stripeErrors = null;
        }
      });
    },
    mountIban: function(e){
      this.ibanElement = this.stripeProxy.elements.create('iban', {
      style: this.stripeOptions.style,
        supportedCountries: ['SEPA'],
      });
      this.ibanElement.mount('#iban-element');
      this.ibanElement.on('change', (event) => {
        if (event.error) {
          this.sepaErrors = event.error.message;
        } else {
          this.sepaErrors = null;
        }
      });
    },
    initVatNumber() {
      if(!this.orderForm.vat_number) {
        Vue.set(this.orderForm, 'vat_number', '');
      }
    },
    initPromocodeFromParams() {
      let element = document.getElementById('params-code');
      if(element) {
        let promocode = element.dataset.paramsPromoCode;
        if(promocode.length > 0) {
          Vue.set(this.orderForm, 'promocode', promocode.toUpperCase());
          this.getAndSetPromocodeId();
        }
      }
    },
    isSingleStepCheckout() {
      return this.product.checkout_version === constants.SINGLE_STEP_CHECKOUT
    },
    isStripePaymentButtonChecked: function() {
      return this.paymentMethod === constants.STRIPE && this.isGetPaymentButtonActive;
    },
    setGetPaymentButtonChecked: function() {
      Vue.set(this, "paymentMethod", constants.STRIPE);
      Vue.set(this, "isGetPaymentButtonActive", true);
    },
    resetStripePaymentMethod: function() {
      Vue.set(this, "isGetPaymentButtonActive", false);
    },
    isStripeChecked: function() {
      return this.paymentMethod === constants.STRIPE;
    },
    isSofortChecked: function() {
      return this.paymentMethod === constants.SOFORT;
    },
    isSepaChecked: function() {
      return this.paymentMethod === constants.SEPA;
    },
    isPaypalChecked: function() {
      return this.paymentMethod === constants.PAYPAL;
    },
    isInvoiceChecked: function() {
      return this.paymentMethod === constants.INVOICE;
    },
    isKlarnaChecked: function () {
      return this.paymentMethod === constants.KLARNA;
    },
    isPayLaterChecked: function() {
      return this.paymentMethod === constants.PAYLATER;
    },
    isSequraChecked: function() {
      return this.paymentMethod === constants.SEQURA;
    },
    isCheckoutSummaryHidden() {
      return !(this.twoStepsCheckoutInfo.steps_completed === 0 && this.paymentPlans.length > 1);
    },
    showAlertOnClosing(event) {
      if (this.checkoutWasClicked) {
        event.preventDefault();
        (event || window.event).returnValue = 'In process!';
      }
    },
    getAndSetPromocodeId: function () {
      let vm = this;
      let params = {
        product_id: vm.orderForm.product_id,
        code: vm.orderForm.promocode,
        addons_present:  vm.product.addons_enabled && !_.isEmpty(vm.addons),
        addons_slugs: vm.checkedAddons.map(addon => addon.source_product_slug)};
        

      let urlParams = vm.buildUrlParams(params);
      let url = `${this.findByCodeUrl}${urlParams}`;

      if (!vm.orderForm.promocode || vm.orderForm.promocode.length == 0) {
        Vue.set(vm.orderForm, 'promocode_id', '');
        Vue.set(vm.orderForm, 'discount_percent', '');
        Vue.set(vm.orderForm, 'discount_amount', '');
        return;
      }

      Vue.set(vm, 'isRecalculating', true);
      Vue.set(vm, 'submitIsDisabledValue', true);

      fetch(url, {
        method: 'get',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'content-type': 'application/json'
        }
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        Vue.set(vm, 'isRecalculating', false);
        Vue.set(vm, 'submitIsDisabledValue', false);

        if (json.errors && (json.errors['find_promo'] || json.errors['not_aplicable'])) {
          console.log("Error: findByCode: ", (json.errors['find_promo'] || json.errors['not_aplicable']));
          vm.promocodeError['find_promo'] = (json.errors['find_promo'] || json.errors['not_aplicable']);
          Vue.set(vm.orderForm, 'promocode_id', '');
          Vue.set(vm.orderForm, 'discount_percent', '');
          Vue.set(vm.orderForm, 'discount_amount', '');
          return;
        } else {
          vm.promocodeError = {};
        }

        if (json.id) {
          Vue.set(vm.orderForm, 'promocode_id', json.id);
          Vue.set(vm.orderForm, 'discount_percent', json.discount_percent);
          Vue.set(vm.orderForm, 'discount_amount', json.discount_amount);
        }
      }).catch(function(err) {
        Vue.set(vm, 'isRecalculating', false);
        Vue.set(vm, 'submitIsDisabledValue', false);
        console.log("Error: getPromocode", err)
      });
    },
    deletePromocodeError(fieldName) {
      Vue.delete(this.promocodeError, fieldName);
    },
    getSaleability() {
      let vm = this;
      if (vm.productUnavailable) { return; }
      let params = {
        country_code: vm.orderForm.country_code,
        state: vm.orderForm.state,
        product_slug: vm.productSlug,
        addons_ids: vm.checkedAddons.map(addon => addon.id),
      };
      let urlParams = vm.buildUrlParams(params);
      let url = `${this.saleabilityUrl}${urlParams}`;
      vm.saleForbidden = true;
      fetch(url, {
        method: 'get',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'content-type': 'application/json'
        }
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        if(Boolean(json.redirect_url)) {
          location.replace(json.redirect_url);
        }

        if (json.errors.length > 0) {
          console.log("Error: getSaleability", json.errors.map(e => e.message).join('. '));
          return;
        }
        if (json.saleable) {
          vm.saleForbidden = false;
        } else {
          Vue.set(vm, 'saleabilityData', json);
          vm.openSaleabilityMessage();
        }
      }).catch(function(err) {
        console.log("Error: getSaleability", err)
      });
    },
    beforeGetPricingData() {
      if(this.doBeforeGetPricingData && typeof this.doBeforeGetPricingData == 'function') {
        this.doBeforeGetPricingData();
      } else {
        this.getPricingData();
      }
    },
    registerLatestGetPricingRequest() {
      let currentGetPricingTS = Date.now();

      this.latestGetPricingDataTS = currentGetPricingTS;

      return currentGetPricingTS;
    },
    checkIfGetPricingRequestIsNotRecentOne(currentGetPricingTS) {
      return currentGetPricingTS != this.latestGetPricingDataTS;
    },
    getPricingData: function(){
      let currentGetPricingTS = this.registerLatestGetPricingRequest();

      let vm = this;

      let urlParams = vm.buildGetPricingDataParams();

      // way to prevent 2 in a row similar pricing calls on qty change
      // going to rework it in the future
      if(this.previousGetPricingUrlParams == urlParams) {
        Vue.set(vm, 'isRecalculating', false);
        Vue.set(vm, 'submitIsDisabledValue', false);
        return;
      } else {
        Vue.set(vm, 'previousGetPricingUrlParams', urlParams);
        setTimeout(
          () => {
            Vue.set(vm, 'previousGetPricingUrlParams', null);
          }, 1000
        );
      }

      let url = `${this.pricingUrl}${urlParams}`;

      Vue.set(vm, 'submitIsDisabledValue', true);
      Vue.set(vm, 'isRecalculating', true);

      fetch(url, {
        method: 'get',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'content-type': 'application/json'
        }
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        if(Boolean(json.redirect_url)) {
          location.replace(json.redirect_url);
        }

        if(vm.checkIfGetPricingRequestIsNotRecentOne(currentGetPricingTS)) {
          return;
        }

        vm.bruttoPrice = json.product_info.brutto_price;
        vm.nettoPrice = json.product_info.netto_price;
        vm.vatAmount = json.product_info.vat_amount;
        vm.shippingBruttoAmount = json.product_info.shipping_brutto_amount;
        vm.shippingNettoAmount = json.product_info.shipping_netto_amount;
        vm.shippingVatAmount = json.product_info.shipping_vat_amount;
        vm.vatPercent = json.product_info.vat_percent;
        vm.originalVatPercent = json.product_info.original_vat_percent;
        vm.inStockLeft = json.product_info.in_stock_left;
        vm.isStockVisible = json.product_info.is_stock_visible;
        vm.discountGross = json.product_info.discount_total_gross_amount;
        vm.discountNet = json.product_info.discount_net_amount;
        vm.orderForm.netto_price = json.product_info.netto_price;
        vm.orderForm.vat_amount = json.product_info.vat_amount;
        vm.nextDiscountNet = json.product_info.discount_next_net_amount;
        vm.nextDiscountGross = json.product_info.discount_next_gross_amount;
        vm.discountVatAmount = json.product_info.discount_vat_amount || 0;
        vm.nextGrossAmount = json.product_info.next_gross_amount;
        vm.nextShippingBruttoAmount = json.product_info.next_shipping_brutto_amount;

        vm.addons.forEach(addon => {
          try {
            let addonInfo = json.addons_info.find(item => item.product_slug == addon.source_product_slug);
            let discount = (addonInfo.discount_total_gross_amount || 0)

            Vue.set(addon, 'bruttoPrice', addonInfo.brutto_price);
            Vue.set(addon, 'nettoPrice', addonInfo.netto_price);
            Vue.set(addon, 'vatAmount', addonInfo.vat_amount);
            Vue.set(addon, 'shippingNettoAmount', addonInfo.shipping_netto_amount);
            Vue.set(addon, 'shippingBruttoAmount', addonInfo.shipping_brutto_amount);
            Vue.set(addon, 'shippingVatAmount', addonInfo.shipping_vat_amount);
            Vue.set(addon, 'vatPercent', addonInfo.vat_percent);
            Vue.set(addon, 'bruttoPricePreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.bruttoPrice);
            Vue.set(addon, 'nettoPricePreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.nettoPrice);
            Vue.set(addon, 'nettoPricePreviewWithDiscount', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.nettoPrice - addonInfo.discount_net_amount);
            Vue.set(addon, 'vatAmountPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.vatAmount);
            Vue.set(addon, 'vatAmountPreviewWithDiscount', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.vatAmount - addonInfo.discount_vat_amount);
            Vue.set(addon, 'bruttoPriceWithDiscount', addon.bruttoPrice - discount);
            Vue.set(addon, 'bruttoPriceWithDiscountPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.bruttoPrice - discount);
            Vue.set(addon, 'discountGross', discount);
            Vue.set(addon, 'discountNet', addonInfo.discount_net_amount);
            Vue.set(addon, 'nextDiscountNet', addonInfo.discount_next_net_amount);
            Vue.set(addon, 'nextDiscountGross', addonInfo.discount_next_gross_amount);
            Vue.set(addon, 'discountGrossPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : -discount);
            Vue.set(addon, 'nextGrossAmount', addonInfo.next_gross_amount);
            Vue.set(addon, 'nextShippingBruttoAmount', addonInfo.next_shipping_brutto_amount);

            Vue.set(addon, 'showMoreTotalDetails', true);

            if(addonInfo.in_stock_left != null) {
              Vue.set(addon, 'inStockLeft', addonInfo.in_stock_left);
              Vue.set(addon, 'isStockVisible', addonInfo.is_stock_visible);
            } else {
              Vue.set(addon, 'inStockLeft', null);
              Vue.set(addon, 'isStockVisible', null);
            }
          } catch (error) { console.error('Error in addons calculations'); }
        });
        vm.recalculateTotalAmounts();
        Vue.set(vm, 'submitIsDisabledValue', false);
        Vue.set(vm, 'isRecalculating', false);
        vm.doForceRefresh();
      }).catch(function(err) {
        Vue.set(vm, 'isRecalculating', false);
        console.log("Error: getPricingData", err);
      });
    },
    //TODO: actually dirty hotfix, better solution required
    doForceRefresh: function() {
      let ua = navigator.userAgent.toLowerCase();
      if ((
            ua.indexOf('safari/') > -1 &&
            ua.indexOf('12.1') > -1
          ) || (
            ua.indexOf('instagram') > -1 &&
            ua.indexOf('ios') > -1)
          ) {
        this.$forceUpdate();
      }
    },
    buildGetPricingDataParams: function() {
      let productParams = JSON.stringify({
        promocode_id: this.orderForm.promocode_id,
        product_slug: this.productSlug,
        payment_plan_id: this.currentPaymentPlan.id,
        quantity: this.orderForm.products_quantity
      });
      let addonsParams = JSON.stringify(_.map(this.addons, a => {
        return { addon_id: a.id, payment_plan_id: a.plan_id, quantity: a.quantity }
      }));
      // addressParamsForPricingData - must be implemented in main.js(separate for us and eu).
      let addressParams = this.addressParamsForPricingData();

      let params = {
        country_code: this.orderForm.country_code,
        is_private_person: this.orderForm.is_private_person,
        vat_number: this.orderForm.vat_number,
        product_info: productParams,
        addons_info: addonsParams,
        address: addressParams
      };

      return this.buildUrlParams(params);
    },
    buildUrlParams: function(params) {
      let urlString = Object.keys(params).map(function(k) {
        return k + "=" + encodeURIComponent(params[k]);
      }).join('&');

      return "?" + urlString;
    },
    setCheckedAddons: function() {
      if (_.has(this.orderForm, 'addons_info') || this.orderForm.addons_info.length === 0) { return; }
      JSON.parse(this.orderForm.addons_info).forEach(addon_info => {
        let addon = this.addons.find(adn => adn.id === addon_info.addon_id);
        if (!!addon) {
          Vue.set(addon, 'isChecked', true);
          Vue.set(addon, 'quantity', addon_info.quantity);
        }
      })
    },
    setIsInvoiceNeeded() {
      if (this.addonsRequireAddress) {
        this.orderForm.is_invoice_needed = true;
      }
    },
    setPaypalVisibilityForAddons: function() {
      this.isPaypalAvailable = this.isPaypalAvailableForFrontendProduct && !this.isPaypalHiddenForCurrentPlan && !this.hasAddonsUnavailableForPaypal;
      if (!this.isPaypalAvailable && this.paymentMethod === constants.PAYPAL) {
        this.paymentMethod = this.isStripeAvailable ? constants.STRIPE : null;
      }
    },
    showAddressFieldsForInvoice() {
      if (this.paymentMethod == constants.INVOICE) {
        this.orderForm.is_invoice_needed = true;
      }
    },
    paymentPlanChanged: function(plan) {
      this.currentPaymentPlan = plan;

      if(this.isPaypalHiddenForCurrentPlan || this.hasAddonsUnavailableForPaypal) {
        this.isPaypalAvailable = false;
        if (this.paymentMethod === constants.PAYPAL) {
          this.paymentMethod = this.isStripeAvailable ? constants.STRIPE : null;
        }
      } else {
        this.isPaypalAvailable = this.isPaypalAvailableForFrontendProduct;
      }
    },
    setAddonAsChecked: function(addon, event) {
      if(this.addonHasInStockLimit(addon) && this.addonNotInStock(addon)) {
        return;
      }
      let notSelectingClick = event.target.className.includes('not-selecting-click');
      if (notSelectingClick) { return; }
      Vue.set(addon, 'isChecked', !addon.isChecked);
      this.beforeGetPricingData();
      this.getSaleability();
    },
    setBumpAddonAsChecked: function() {
      this.getSaleability();
    },
    orderNow: function(_event) {
      var vm = this;

      if(vm.zeroTotalAndNotTrial) {
        vm.showCommonErrorModal(vm.t('errors.at_least_one_addon_should_be_selected'));
        return;
      }

      if(vm.notInStock) {
        vm.showNotInStockModal();
        return;
      }

      // Check payment plan id due to USCOP-222
      if (vm.hidePaymentPlans) {
        if ($('input[name=payment_plan_id]').val() != vm.paymentPlans[0].id.toString()) {
          return;
        }
      } else {
        if (!vm.paymentPlans.map(plan => plan.id.toString()).includes($('input[name=payment_plan_id]:checked').val())) {
          return;
        }
      }
      vm.validateBlackListEmail(vm);
    },
    validateBlackListEmail(vm) {
      vm.checkoutWasClicked = true;

      this.$http.get(vm.checkBlacklistEmailUrl, {
        params: {
          email: vm.orderForm.email,
          vendor_id: vm.product.vendor_id
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        if(!json.blocked) {
          vm.validatePaymentMethod(vm);
        } else {
          vm.checkoutWasClicked = false;
          window.location = json.redirect_url
          return false;
        }
      }).catch((error) => {
        vm.checkoutWasClicked = false;
        console.log('Error:', error);
      });
    },
    validatePaymentMethod: function(vm) {
      vm.checkoutWasClicked = true;

      this.$http.get(this.validatePaymentMethodUrl, {
        params: {
          payment_method: vm.paymentMethod
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        if(json.success) {
          vm.checkRecentProductChanges(vm, vm.orderNowSuccessCallback);
        } else {
          vm.orderFormErrors = { payment_method:  I18n.t('js.checkout.errors.this_payment_method_is_currently_unavailable') }
          $('html, body').animate({
            scrollTop: $('#payment-method-errors').offset().top - 120
          }, 250);
          return false;
        }
      }).catch((error) => {
        vm.checkoutWasClicked = false;
        console.log('Error:', error);
      });
    },
    checkRecentProductChanges: function(vm, successCallback) {
      vm.checkoutWasClicked = true;

      vm.$http.get(vm.checkRecentProductChangesUrl, {
        params: {
          product_id: vm.orderForm.product_id,
          checkout_time: vm.checkoutOpenedTime,
          addon_ids: vm.checkedAddons.map(addon => addon.id)
        }
      }).then((response) => {
        return response.json();
      }).then((json) => {
        vm.checkoutWasClicked = false;
        if(json.reload_required) {
          vm.showForceReloadPopUp = true;
        } else if(Boolean(json.redirect_url)) {
          location.replace(json.redirect_url);
        } else {
          successCallback(vm);
        }
      }).catch((error) => {
        vm.checkoutWasClicked = false;
        console.log('Error:', error);
      });
    },
    closeForceReloadPopUp: function() {
      this.checkoutWasClicked = false;
      this.showForceReloadPopUp = false;
    },
    confirmForceReloadPopUp: function() {
      this.showForceReloadPopUp = false;
      if (this.isTwoStepCheckout()) {
        sessionStorage.setItem('productSlug', this.product.slug);
        sessionStorage.setItem('firstStepOrderFormId', this.twoStepsCheckoutInfo.first_step_order_form_id);
      } else {
        sessionStorage.setItem('buyerInfo', JSON.stringify(this.getFilledBuyerInfo()));
      }

      location.replace(location.href);
    },
    getFilledBuyerInfo: function() {
      if(!this.orderForm) { return; }

      const filteredObject = BUYER_INFO_FIELDS.reduce((acc, key) => {
        if (key in this.orderForm) { acc[key] = this.orderForm[key]; }
        return acc;
      }, {});
      return filteredObject;
    },
    orderNowSuccessCallback: function(vm) {
      if(vm.isStripePaymentButtonChecked()) {
        vm.getPaymentButtonFormSubmit();
      } else if (vm.isStripeChecked()) {
        vm.stripeCheckout();
      } else if (vm.isSepaChecked()) {
        vm.sepaCheckout();
      } else if (vm.isInvoiceChecked()) {
        vm.invoiceCheckout();
      } else if (vm.isKlarnaChecked()) {
        vm.klarnaCheckout();
      } else if (vm.isSequraChecked()) {
        vm.sequraCheckout();
      } else {
        if(!vm.formDataValid()) {
          return false;
        }

        $('#addons_info').val(vm.getCheckedAddonsParams());
        Vue.set(vm, 'submitIsDisabledValue', true);
        $('form').submit();
      }
    },
    handleFirstStepCheckout: function(event){
      if(!this.formDataValid()) {
        return false;
      }

      // backup phone code for possible contact data edit
      this.phone_code_origin = this.orderForm.phone_code;

      this.$http.post(this.firstStepCheckoutUrl.replace(':slug', this.product.slug) , { first_step_info: this.orderForm }
        ).then(response => {
          return response.json();
        }).then((response) => {
          if(Boolean(response.redirect_url)) {
            location.replace(response.redirect_url);
          } else {
            this.twoStepsCheckoutInfo = JSON.parse(response.two_steps_checkout_info);
            this.orderForm = JSON.parse(response.order_form);
            // Scroll to top on Second Step init
            $('html, body').animate({ scrollTop: 0 });
            setTimeout(function(){
              this.mountGetPaymentButton();
              this.mountStripe();
              this.mountIban();
              if(this.isNewCheckoutTheme) {
                this.updateNew2StepCheckoutProdDecription();
              }
              this.initPromocodeFromParams();
            }.bind(this), 200);
          }
        }, response => {
          event.target.disabled = false;
          this.firstStepCheckoutErrors = response.body;

          let needReload = Object.keys(this.firstStepCheckoutErrors).map((num) => { return !!document.getElementById(`${num}`) });
          if(needReload.includes(false)) {
            sessionStorage.setItem('buyerInfo', JSON.stringify(this.getFilledBuyerInfo()));
            location.replace(location.href);
          }

          if (response.body['email']) {
            this.firstStepCheckoutErrors['email'] = response.body['email'][0]
          }
        });
    },
    backToStepOne: function() {
      this.twoStepsCheckoutInfo.steps_completed = 0;

      // restore phone_code and phone for edit
      if(this.orderForm.phone) {
        this.orderForm.phone_code = this.phone_code_origin
        this.orderForm.phone = this.orderForm.phone.replace(this.phone_code_origin, '');
      }
    },
    formDataValid: function() {
      let vm = this,
          formIsValid = true,
          onFirstStep = vm.isOnFirstStep(),
          invoiceChecked = vm.isInvoiceChecked(),
          checkoutVersion = new Date().getTime();

      const FORM_SELECTOR = '#checkout-form';
      const CLASS_MOD_GROUP_ERROR = '.mod-group-error:first';

      let fields = {
          'company_name': {
            r: false,
            rx: false,
            m: this.t('errors.missing_parameter')
          },
          'first_name': {
            r: true,
            rx: false,
            m: this.t('errors.missing_parameter')
          },
          'last_name': {
            r: true,
            rx: false,
            m: this.t('errors.missing_parameter')
          },
          'email': {
            r: true,
            rx: regexps.EMAIL_ADDRESS,
            m: this.t(vm.orderForm['email'] === "" ? 'errors.missing_parameter' : 'errors.email')
          },
          'phone': {
            r: this.showPhone || false,
            rx: regexps.PHONE_WITHOUT_CODE,
            m: this.t('errors.phone')
          },
          'street': {
            r: false,
            rx: false,
            m: this.t('errors.invoice.address')
          },
          'house_number': {
            r: false,
            rx: false,
            m: this.t('errors.invoice.address')
          },
          'address_details': {
            r: false,
            rx: false
          },
          'zipcode': {
            r: false,
            rx: regexps.ZIPCODE,
            m: this.t('errors.invoice.zipcode')
          },
          'city': {
            r: false,
            rx: false,
            m: this.t('errors.invoice.city')
          },
          'state': {
            r: false,
            rx: false,
            m: this.t('errors.missing_parameter')
          },
          'vat_number': {
            r: false,
            rx: false,
            m: this.t('errors.missing_parameter')
          },
      };

      if(this.orderForm.is_private_person == "false") {
        fields.company_name.r = true;
        if (this.product.buyer_data_settings.vat_number) {
          fields.vat_number.r = true;
        }
      }

      if (this.checkIsAddressRequired()) {
        fields.street.r = true;
        fields.house_number.r = this.useHouseNumber;
        fields.zipcode.r = true;
        fields.city.r = true;

        if(this.countryHasStates) {
          fields.state.r = true;
        }
      }

      if (this.isOnSecondStep()){
        fields.phone.rx = regexps.PHONE;
      }

      _.forEach(fields, function (cfg, fieldName) {
        let field = vm.orderForm[fieldName],
            fieldIsValid = true,
            isBlank = _.trim(field) === "";

            vm.deleteError(fieldName);
            if(onFirstStep || invoiceChecked) { vm.deleteFirstStepError(fieldName); }

        if (!!cfg.r) {
          fieldIsValid = !isBlank;
        }

        if (!!cfg.rx && !!fieldIsValid && !isBlank) {
          fieldIsValid = !!cfg.rx.test(field);
        }

        // particular check for phone_code + phone if available and required
        if(fieldName == 'phone' && vm.orderForm['phone_code']) {
          fieldIsValid = !!regexps.PHONE.test(`${vm.orderForm['phone_code']}${vm.orderForm['phone']}`);
        }

        if (!fieldIsValid) {
          vm.setError(fieldName, cfg.m || undefined);
          if(onFirstStep || invoiceChecked) { vm.setFirstStepError(fieldName, cfg.m || undefined); }
        }

        if (!!formIsValid && !fieldIsValid) {
          formIsValid = false
        }
      });

      if (this.orderFormErrors['vat_number']) {
        formIsValid = false
      }

      if(!formIsValid && vm.isOnSecondStep()) { vm.backToStepOne(); }

      if(!formIsValid) {
        this.$nextTick().then(() => {
          try {
            this.$http.post(
              '/checkout_validations',
              JSON.stringify({
                company_name: this.orderForm.company_name,
                first_name: this.orderForm.first_name,
                last_name: this.orderForm.last_name,
                email: this.orderForm.email,
                phone: this.orderForm.phone,
                street: this.orderForm.street,
                house_number: this.orderForm.house_number,
                address_details: this.orderForm.address_details,
                zipcode: this.orderForm.zipcode,
                city: this.orderForm.city,
                state: this.orderForm.state,
                errors_list: this.orderFormErrors,
                checkout_version: checkoutVersion
              }),
              //{ timeout: 2000 }
            ).then(response => {
              // no callback
            }, response => {
              // no callback
            }).catch((error) => {
              // no error handling
            });
          } catch (e) {
            // no error handling
          }
        });

        setTimeout(()=>{
          try {
            this.checkoutWasClicked = false;
            $('html, body, #iframe_checkout_absolute_card').animate({
              scrollTop: Math.max(0, $(FORM_SELECTOR).find(CLASS_MOD_GROUP_ERROR).first().offset().top - 80)
            }, 250);
          } catch(e) {
            //empty
          }
        }, 300);
      }

      return formIsValid;
    },
    checkIsAddressRequired: function() {
      return this.addressRequired;
    },
    invoiceCheckout: function() {
      if(!this.formDataValid()) {
        return false;
      }

      $('#addons_info').val(this.getCheckedAddonsParams());
      Vue.set(this, 'submitIsDisabledValue', true);
      $('form').submit();
    },
    stripeCheckout: function(event) {
      let vm = this;
      let name = this.orderForm.first_name + ' ' + this.orderForm.last_name,
          email = this.orderForm.email,
          currency = this.orderForm.currency.toLowerCase(),
          totalAmount = this.totalBruttoAmountPreview;

      if(!this.formDataValid()) {
        return false
      }
      $('#addons_info').val(vm.getCheckedAddonsParams());
      vm.checkoutWasClicked = true;
      var currentForm = document.querySelector('form#checkout_order_form');
      var formData = new FormData(currentForm);
      var order = {}

      for(let [key, value] of formData) {
        order[key] = value
      }

      vm.stripeProxy.doStripePaymentAndSubmit(
        { card: this.card, name: name, email: email, currency, totalAmount: totalAmount, order: order },
        (result) => {
          vm.checkoutWasClicked = false;
          Vue.set(vm, 'submitIsDisabledValue', true);
          location.href = vm.setUrl()
        },
        (result) => {
          vm.checkoutWasClicked = false;
          vm.stripeErrors = stripeValidationModule.stripeErrors(result);
        });
    },
    sepaCheckout: function(event) {
      let vm = this;
      let name = this.orderForm.first_name + ' ' + this.orderForm.last_name,
          email = this.orderForm.email,
          currency = this.orderForm.currency.toLowerCase(),
          totalAmount = this.totalBruttoAmountPreview;

      if(!this.formDataValid()) {
        return false
      }
      $('#addons_info').val(vm.getCheckedAddonsParams());
      vm.checkoutWasClicked = true;
      var currentForm = document.querySelector('form#checkout_order_form');
      var formData = new FormData(currentForm);
      var order = {}

      for(let [key, value] of formData) {
        order[key] = value
      }
      vm.stripeProxy.doSepaPaymentAndSubmit(
        {
          card: this.ibanElement,
          name: name,
          email: email,
          currency: currency,
          totalAmount: totalAmount,
          order: order,
          address: {
            line1: (vm.orderForm.is_private_person ? vm.orderForm.street : vm.orderForm.company_name),
            city: vm.orderForm.city,
            state: vm.orderForm.state,
            postal_code: vm.orderForm.zipcode,
            country: vm.orderForm.country_code
          }
        },
        (result) => {
          vm.checkoutWasClicked = false;
          Vue.set(vm, 'submitIsDisabledValue', true);
          location.href = vm.setUrl()
        },
        (result) => {
          vm.checkoutWasClicked = false;
          if (result.error.code === 'missing_parameter') {
            vm.sepaErrors = vm.t('sepa_errors.missing_parameter');
          } else if (result.error.message == "Invalid IBAN structure") {
            vm.sepaErrors = vm.t('sepa_errors.invalid_iban');
          } else {
            vm.sepaErrors = stripeValidationModule.stripeErrors(result);
          }
        }
      );
    },
    klarnaCheckout: function (event) {
      if (!this.formDataValid()) {
        return false
      }

      $('#addons_info').val(this.getCheckedAddonsParams());
      Vue.set(this, 'submitIsDisabledValue', true);
      $('form').submit();
    },
    sequraCheckout: function (event) {
      let vm = this;
      if (!vm.formDataValid()) {
        return false
      }

      $('#addons_info').val(vm.getCheckedAddonsParams());
      var currentForm = document.querySelector('form#checkout_order_form');
      var formData = new FormData(currentForm);
      var order = {}

      for(let [key, value] of formData) {
        order[key] = value
      }

      Vue.set(vm, 'submitIsDisabledValue', true);

      fetch(vm.sequraOrdersUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(order)
      }).then(function(response) {
        return response.json();
      }).then(function(json) {
        if (json.iframe && json.iframe.length > 0) {
          let str = `<div id='copecart_sequra_iframe_block'>${json.iframe}</div>`;
          let range = document.createRange();
          let documentFragment = range.createContextualFragment(str);
          document.body.appendChild(documentFragment);

          vm.$nextTick().then(() => {
            (function(){
              var sequraCallbackFunction = function() {
                window.__enableCheckout__();
                document.getElementById('copecart_sequra_iframe_block').remove();
              }
              let sequraCallbackCheckInterval = setInterval(()=> {
                if(window.SequraFormInstance) {
                  clearInterval(sequraCallbackCheckInterval);
                  window.SequraFormInstance.setCloseCallback(sequraCallbackFunction);
                  window.SequraFormInstance.show();
                }
              }, 500);
            })();
          });
        } else {
          Vue.set(vm, 'submitIsDisabledValue', false);
          toastr.error(I18n.t('js.checkout.sequra_order_failed'));
        }
      })
    },
    trackIfPrivatePerson: function(){
      if (this.allowedForAll) {
        this.isPrivate = this.orderForm.is_private_person.toString() == 'true';
      } else {
        this.isPrivate = this.onlyPrivatePersons;
      }

      if (this.isPrivate) {
        Vue.set(this.orderForm, 'vat_number', '');
        Vue.set(this.orderForm, 'company_name', '');
        Vue.set(this, 'agreeRefundPolicy', false);
      } else {
        Vue.set(this, 'agreeRefundPolicy', true);
      }
    },
    toggleMobileShowMore: function(){
      this.isMobileShowMoreOpen = !this.isMobileShowMoreOpen;
    },
    isVatValidationSkippable: function(){
      return !this.isPrivate
        && _.includes(constants.VAT_PERMISSABLE_COUNTRIES, this.orderForm.country_code);
    },
    validateVatNumber: function() {
      let vm = this;
      if(vm.isVatValidationSkippable() || vm.orderForm.vat_number == '' || !vm.checkVatEnabled) {
        vm.deleteError('vat_number');
        vm.deleteFirstStepError('vat_number');
        return;
      }
      let url = `/validate_vat?country_code=${vm.orderForm.country_code}&vat_number=${vm.orderForm.vat_number}`;

      fetch(url, {
        method: 'get',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'content-type': 'application/json'
        }
      }).then(function(response) {
        return response.json();
      }).then(function(json) {
        if (json.result == false) {
          let errorMsg = json.errors.vat_number ? json.errors.vat_number.join('; ') : "";
          vm.setError('vat_number', errorMsg);
          vm.setFirstStepError('vat_number', json.errors.vat_number);
        } else {
          vm.deleteError('vat_number');
          vm.deleteFirstStepError('vat_number');
        }
      }).catch(function(err) {
        console.log("Error: validateVatNumber", err)
      });
    },
    getCheckedAddonsParams: function() {
      let arr = _.map(this.addons.filter(addon => addon.isChecked), addon => {
        return {
          quantity: addon.quantity,
          addon_id: addon.id
        }
      });
      return JSON.stringify(arr);
    },
    getAddonTotalValue: function(addon, type) {
      return valueAsCurrency(parseFloat(addon[type]), this.currencySign);
    },
    withDiscount: function () {
      return this.discountGross != 0 && (this.orderForm.discount_amount != 0 || this.orderForm.discount_percent != 0);
    },
    discountTrialDetails: function () {
      if (this.orderForm.discount_amount == 0 && this.orderForm.discount_percent > 0)  {
        return this.orderForm.discount_percent + '% (-' + this.discountGross + ' ' + this.currencySign + ') ';
      }
      if (this.orderForm.discount_amount > 0 && this.orderForm.discount_percent == 0)  {
        return this.orderForm.discount_amount + ' ' + this.currencySign + '(' + this.discountGross + ' ' + this.currencySign + ') ';
      }
      if (this.orderForm.discount_amount > 0 && this.orderForm.discount_percent > 0)  {
        return this.orderForm.discount_percent + '% + ' + this.orderForm.discount_amount + ' ' + this.currencySign + '(' + this.discountGross + ' ' + this.currencySign + ') ';
      }
    },
    addonDiscountTrialDetails: function (addon) {
      if (this.orderForm.discount_amount == 0 && this.orderForm.discount_percent > 0)  {
        return this.orderForm.discount_percent + '% (-' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
      if (this.orderForm.discount_amount > 0 && this.orderForm.discount_percent == 0)  {
        return this.orderForm.discount_amount + ' ' + this.currencySign + '(' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
      if (this.orderForm.discount_amount > 0 && this.orderForm.discount_percent > 0)  {
        return this.orderForm.discount_percent + '% + ' + this.orderForm.discount_amount + ' ' + this.currencySign + '(' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
    },
    bruttoPriceWithDiscountPreview: function () {
      let discount = this.withDiscount() ? this.discountGross : 0
      return this.isProductTrial ? this.orderForm.default_trial_price : this.productBruttoAmount - discount;
    },
    discountGrossPreview: function () {
      if (!this.withDiscount()) { return 0 }
      return this.isProductTrial ? this.orderForm.default_trial_price : -this.discountGross;
    },
    toogleAddonDetails: function(addon) {
      this.$nextTick().then(() => {
        Vue.set(addon, 'showMoreDetails', !addon.showMoreDetails);
      });
    },
    toogleAddonTotalDetails: function(addon, e) {
      Vue.set(addon, 'showMoreTotalDetails', !addon.showMoreTotalDetails);
    },
    toggleTotalProductDetails: function() {
      Vue.set(this, 'showMoreProductDetails', !this.showMoreProductDetails);
    },
    validateAddress: function() {
      this.validateField('address', null, !this.checkIsAddressRequired());
    },
    validateStreet: function() {
      this.validateField('street', null, !this.checkIsAddressRequired());
    },
    validateHouseNumber: function() {
      this.validateField('house_number', null, !this.checkIsAddressRequired());
    },
    validateZipCode: function() {
      this.validateField('zipcode', regexps.ZIPCODE);
    },
    validateCity: function() {
      this.validateField('city', null, !this.checkIsAddressRequired());
    },
    validateState: function() {
      this.validateField('state', null, !(this.checkIsAddressRequired() && this.countryHasStates));
    },
    validatePhone: function() {
      this.validateField('phone', regexps.PHONE_WITHOUT_CODE, true);
    },
    pickCategoryOption: function(category_id, event){
      this.pickedCategoryOptions[category_id] = event.target.value;
    },
    deleteError(fieldName) {
      Vue.delete(this.orderFormErrors, fieldName);
    },
    setError(fieldName, message = this.t('errors.invalid')) {
      this.$nextTick().then(() => {
        Vue.set(this.orderFormErrors, fieldName, message);
      });
    },
    setFirstStepError(fieldName, message = this.t('errors.invalid')) {
      this.$nextTick().then(() => {
        Vue.set(this.firstStepCheckoutErrors, fieldName, message);
      });
    },
    deleteFirstStepError(fieldName) {
      Vue.delete(this.firstStepCheckoutErrors, fieldName);
    },
    toFloatOrZero: toFloatOrZero,
    vatRate() {
      return this.toFloatOrZero(this.vatPercent) / 100;
    },
    originalVatRate() {
      return this.toFloatOrZero(this.originalVatPercent) / 100;
    },
    validateField(name, regex, allowBlank = false) {
      let vm = this;
      let value = vm.orderForm[name];
      vm.deleteError(name);
      if(allowBlank && !value) {
        return;
      }
      if(!allowBlank && !_.trim(value)) {
        vm.setError(name, this.t('errors.missing_parameter'));
      }
      if(regex && !regex.test(value)) {
        vm.setError(name);
      }
    },
    hidePaymentErrorsModal() {
      this.showPaymentErrorsModal = false;
    },
    t: function(key) {
      return I18n.t(key, { scope: 'js.checkout' });
    },
    formattedAmount(amount) {
      if (isNaN(parseFloat(amount))) { return 0; }
      return parseFloat(amount).toFixed(2).replace('.', ',');
    },
    formatNumber: formatNumber,
    asCurrency: function(value, currency) {
      return valueAsCurrency(value, currency);
    },
    recalculateTotalAmounts() {
      Vue.set(this, 'productBruttoAmount', this.bruttoPrice);
      Vue.set(this, 'productNettoAmount', this.nettoPrice);
      Vue.set(this, 'productVatAmount', this.vatAmount);
      Vue.set(this, 'productDiscountGross', this.discountGross);

      let totalBruttoAmount = parseFloat(this.bruttoPrice) + this.addonsTotalAmount - this.discountGross - this.addonsTotalDiscount;
      let totalBruttoAmountPreview = parseFloat(this.bruttoPricePreview) + this.addonsTotalAmountPreview + this.discountGrossPreview() + this.addonsTotalDiscountPreview;

      if(this.paymentRequest) {
        this.paymentRequest.update({
          total: {
            label: this.product.name,
            amount: parseInt((parseFloat(totalBruttoAmountPreview) * 100).toFixed(2))
          }
        })
      }

      Vue.set(this, 'totalBruttoAmount', totalBruttoAmount);
      Vue.set(this, 'totalBruttoAmountPreview', totalBruttoAmountPreview);

      let maxAddonAmount = _.max(_.compact(this.checkedAddons.map(addon => addon.bruttoPrice)));
      let maxAmount = _.max([maxAddonAmount, this.bruttoPrice, totalBruttoAmountPreview]);

      this.setSepaAvailable(maxAmount <= parseFloat(this.maxSepaBruttoPrice));

      this.paymentPlans.forEach(plan => {
        if(plan.plan_type === constants.ONE_TIME_PAYMENT) {
          plan.short_description = valueAsCurrency(this.parseInternationalFloat(plan.first_payment), this.currencySign);
        }
      });

      this.addons.forEach(addon => {
        if(addon.plan_type === constants.ONE_TIME_PAYMENT) {
          addon.plan_short_description = ` ${valueAsCurrency(this.parseInternationalFloat(addon.nettoPricePreview), this.currencySign)}`;
        }
      });
    },
    setSepaAvailable(available) {
      let isDailyInstallment = this.currentPaymentPlan.plan_type == constants.BREAKDOWN_PAYMENT && this.currentPaymentPlan.frequency == 'daily';
      let checkedAddonsWithDailyBreakdown = this.checkedAddons.some(addon => addon.plan_type == constants.BREAKDOWN_PAYMENT && addon.plan_frequency == 'daily');
      let withinSepaLimit = this.maxCharge <= this.maxSepaBruttoPrice;
      let newSepaState = available && this.paymentMethods.sepa && withinSepaLimit && !isDailyInstallment && !checkedAddonsWithDailyBreakdown;

      Vue.set(this, 'isSepaAvailable', newSepaState);

      if (!newSepaState && this.isSepaChecked()) { this.paymentMethod = null; }
    },
    setFrontendProductQuantityForAddons(){
      this.normalAddons.forEach(addon => {
        if(addon.use_frontend_product_quantity){ this.syncQuantityWithFrontendProduct(addon) }
      });
    },
    syncQuantityWithFrontendProduct(addon){
      let oldQty = addon.quantity;
      let frontendProductQuantity = parseInt(this.orderForm.products_quantity);
      let targetAmount = addon.max_quantity >= frontendProductQuantity ? parseInt(frontendProductQuantity) : addon.max_quantity
      targetAmount = targetAmount == null ? frontendProductQuantity : targetAmount;
      Vue.set(addon, 'quantity', targetAmount);

      if(targetAmount != oldQty) { this.beforeGetPricingData(); }
    },
    productTypeTitle(productType){
      return this.t('product_type')[productType];
    },
    setPhoneCode(countryChanged) {
      if (this.showPhone && (countryChanged || this.orderForm.phone_code.length == 0) && this.orderForm.country_code) {
        let countryInfo = this.countriesInfo.find(item => item.code == this.orderForm.country_code);

        if (countryInfo) {
          this.orderForm.phone_code = countryInfo.dial_code;
        }
      }
    },
    setState() {
      let vm = this;
      vm.$nextTick(() => {
        Vue.set(vm.orderForm, 'state', vm.countryHasStates ? Object.keys(vm.statesInfo[vm.orderForm.country_code])[0] : '');
        Vue.set(vm.orderFormErrors, 'state', '');
        Vue.set(vm.firstStepCheckoutErrors, 'state', '');
      });
    },
    setUrl() {
      let orderPath = $('#order_path').val();
      let funnelSessionId = document.getElementById('order_id').dataset.funnelSessionId;
      if (funnelSessionId) {
        return `${orderPath}?funnel_session_id=${funnelSessionId}`
      } else {
        return orderPath
      }
    },
    showNotInStockModal() {
      $('#notInStockInfo').addClass('mod-open');
    },
    hideNotInStockModal() {
      $('#notInStockInfo').removeClass('mod-open');
    },
    checkPlanDescription: function(plan, addon = null) {
      return plan.description_checkout || '';
    },
    abonementShortDescription: function (plan, addon) {
      let result = [];
      let vm = addon ? addon : this;
      let firstDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.discountNet) : this.parseInternationalFloat(vm.discountGross);
      let nextDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.nextDiscountNet) : this.parseInternationalFloat(vm.nextDiscountGross);
      let firstPayment = this.formattedAmount(this.parseInternationalFloat(plan.first_payment) - firstDiscount);

      this.pushFirstPaymentTitle(
        result, 'js.decorators.plan_info_helpers.first_payment',
        (firstDiscount ? `<s>${plan.first_payment}</s> ${firstPayment}` : firstPayment)
      )

      let secondPayment = this.formattedAmount(this.parseInternationalFloat(plan.next_payments) - nextDiscount);
      if(plan.second_payment_in) {
        this.pushSecondPaymentInTitle(
          result, 'js.decorators.plan_info_helpers.second_payment_in', plan,
          (firstDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment)
        )
      }
      result.push(
        I18n.t(
          'js.decorators.plan_info_helpers.then_next_payments', {
            next_payments: (firstDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment),
            plan_frequency_abbreviation: this.planFrequency(plan.frequency),
            currency_sign: this.currencySign
          }
        )
      )
      if (this.isShippable(plan)) {
        this.pushShippableTitle(result, plan)
      }
      return result.join(', ')
    },
    breakdownShortDescription: function(plan, addon) {
      let result = [];
      let vm = addon ? addon : this;
      let firstDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.discountNet) : this.parseInternationalFloat(vm.discountGross);
      let nextDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.nextDiscountNet) : this.parseInternationalFloat(vm.nextDiscountGross);
      let firstPayment = this.parseInternationalFloat(plan.first_payment) - firstDiscount;

      this.pushFirstPaymentTitle(
        result, 'js.decorators.plan_info_helpers.first_rate',
        (firstDiscount ? `<s>${plan.first_payment}</s> ${this.formattedAmount(firstPayment)}` : this.formattedAmount(firstPayment))
      )

      let secondPayment = this.formattedAmount(this.parseInternationalFloat(plan.next_payments) - nextDiscount);
      if(plan.second_payment_in) {
        this.pushSecondPaymentInTitle(
          result, 'js.decorators.plan_info_helpers.second_payment_in', plan,
          (nextDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment)
        )
      }

      let paymentsQtty = plan.second_payment_in ? plan.payments_count - 1 : plan.payments_count;
      let nextPayments = this.parseInternationalFloat(plan.next_payments) - nextDiscount;
      if (paymentsQtty > 0) {
        result.push(
          I18n.t(
            'js.decorators.plan_info_helpers.then_n_times_next_payments', {
              payments_qtty: paymentsQtty,
              next_payments: (nextDiscount ? `<s>${plan.next_payments}</s> ${this.formattedAmount(nextPayments)}` : this.formattedAmount(nextPayments)),
              plan_frequency_abbreviation: this.planFrequency(plan.frequency),
              currency_sign: this.currencySign
            }
          )
        )
      }

      let totalBreakdown = (this.parseInternationalFloat(plan.first_payment) +
          this.parseInternationalFloat(plan.next_payments) * plan.payments_count) *
          (1 + (this.orderForm.has_net_price ? this.vatPercent / 100 : 0.0));
      result.push(
        I18n.t(
          'js.decorators.plan_info_helpers.total_breakdown', {
            breakdown_sum: (firstDiscount ? `<s>${this.formattedAmount(totalBreakdown)}</s> ${this.formattedAmount(this.parseInternationalFloat(firstPayment) + (this.parseInternationalFloat(nextPayments) * paymentsQtty))}` : this.formattedAmount(totalBreakdown)),
            currency_sign: this.currencySign
          }
        )
      )

      if (this.isShippable(plan)) {
        this.pushShippableTitle(result, plan)
      }
      return result.join(', ');
    },
    totalBreakdown: function(plan) {
      let value = +this.grossFirstPayments() + (+this.grossNextPayments() * parseInt(plan.payments_count));

      return isNaN(value) ? 0 : value.toFixed(2);
    },
    addonsTotalBreakdown: function(addon) {
      let value = +this.grossAddonFirstPayments(addon) + (+this.grossAddonNextPayments(addon) * parseInt(addon.plan.payments_count));

      return value.toFixed(2);
    },
    grossFirstPayments: function() {
      let value = this.parseInternationalFloat(this.bruttoPrice) - this.parseInternationalFloat(this.discountGross);

      return value.toFixed(2);
    },
    grossNextPayments: function() {
      let value = this.parseInternationalFloat(this.nextGrossAmount) - this.parseInternationalFloat(this.nextDiscountGross);

      return isNaN(value) ? 0 : value.toFixed(2);
    },
    grossAddonFirstPayments: function(addon) {
      let value = this.parseInternationalFloat(addon.bruttoPrice) - this.parseInternationalFloat(addon.discountGross);

      return value.toFixed(2);
    },
    grossAddonNextPayments: function(addon) {
      let value = this.parseInternationalFloat(addon.nextGrossAmount) - this.parseInternationalFloat(addon.nextDiscountGross);

      return value.toFixed(2);
    },
    isBreakdownPayment: function(plan) {
      return plan.plan_type == constants.BREAKDOWN_PAYMENT;
    },
    isOneTimePayment: function(plan) {
      return plan.plan_type == constants.ONE_TIME_PAYMENT;
    },
    pushFirstPaymentTitle: function(result, localeName, payment) {
      return result.push(
        I18n.t(localeName, {
          currency_sign: this.currencySign,
          first_payment: payment
        })
      )
    },
    pushSecondPaymentInTitle: function(result, localeName, plan, payment) {
      return result.push(
        I18n.t(localeName, {
            second_payment_in: plan.second_payment_in,
            days: this.planDays(plan.second_payment_in),
            next_payments: payment,
            currency_sign: this.currencySign
          }
        )
      )
    },
    pushShippableTitle: function(result, plan) {
      const shipping_description_type = plan.plan_type === constants.ONE_TIME_PAYMENT || plan.charge_shipping_once ? 'one_time_shipping' : 'recurring_shipping';

      return result.push(
        I18n.t(
          `js.decorators.plan_info_helpers.${shipping_description_type}`, {
            shipping_price: this.formattedAmount(parseFloat(plan.shipping_price)),
            currency_sign: this.currencySign
          }
        )
      )
    },
    isShippable: function(plan) {
      return parseFloat(plan.shipping_price) > 0.0;
    },
    planFrequency: function(frequency) {
      return I18n.t(`js.decorators.plan_info_helpers.plan_frequency_abbreviation.${frequency}`)
    },
    planDays: function(days) {
      return I18n.t(`js.decorators.plan_info_helpers.pluralize_days.${this.maybePluralize(days, 'day')}`)
    },
    maybePluralize: function(count, noun, suffix = 's') {
      return `${noun}${count !== 1 ? suffix : ''}`;
    },
    increaseQuantity(fieldSelector) {
      let field = $(fieldSelector).get(0);
      field.stepUp(1);
      this.dispatchChangeEventOnField(field);
    },
    decreaseQuantity(fieldSelector) {
      let field = $(fieldSelector).get(0);
      field.stepDown(1);
      this.dispatchChangeEventOnField(field);
    },
    dispatchChangeEventOnField(field) {
      field.dispatchEvent(new Event('change', {
        bubbles: true,
        cancelable: true,
      }));
    },
    toggleElementClass(elementSelector, classToToggle) {
      $(elementSelector).toggleClass(classToToggle);
    },
    closeSepaLineOnePopup() {
      this.sepaLineOnePopupVisible = false;
    },
    noRecurringPayments: function () {
      let nonRecurringAddons = this.checkedAddons.filter(addon => addon.plan_type === constants.RECURRING_PAYMENT).length === 0;
      let onlyRegularAddons = this.checkedAddons.filter(addon => !addon.trial_days).length === this.checkedAddons.length;
      let nonRecurringPlanType = this.currentPaymentPlan.plan_type !== constants.RECURRING_PAYMENT;
      let noRecurringPayments = nonRecurringAddons && this.paymentMethods.invoice && onlyRegularAddons && nonRecurringPlanType;

      if (!noRecurringPayments && this.isInvoiceChecked()) { this.paymentMethod = null; }

      return noRecurringPayments;
    },
    isOnlyOneTimePayments: function () {
      let addonsOneTimePayment = this.checkedAddons.filter(addon => addon.plan_type !== constants.ONE_TIME_PAYMENT).length === 0;
      let oneTimePaymentPlan = this.currentPaymentPlan.plan_type === constants.ONE_TIME_PAYMENT;

      return oneTimePaymentPlan && addonsOneTimePayment;
    },
    onlyRegularAddons: function() {
      return this.checkedAddons.filter(addon => !addon.trial_days).length === this.checkedAddons.length;
    },
    enableCheckout: function() {
      Vue.set(this, 'submitIsDisabledValue', false);
    },
  },
  watch: {
    'totalBruttoAmountPreview': function() {
      // disable google/apple/MS pay in case of trials (0 total)
      if(this.isStripeAvailable && this.isStripePaymentButtonEnabled && !this.productDiscountGross) {
        this.isGetPaymentButtonActive = this.paymentMethod == constants.STRIPE && this.notZeroTotal;
        this.isStripePaymentButtonAvailable = this.notZeroTotal;
      }
    },
    // whenever country changes, this function will run
    'orderForm.country_code': function() {
      if(this.skipCountryCodeWatcher) {
        this.skipCountryCodeWatcher = false;
        return;
      }
      this.beforeGetPricingData();
      this.getSaleability();
      this.validateVatNumber();
      this.setPhoneCode(true);
      this.setState();
    },
    'orderForm.vat_number': function() {
      let vm = this;

      if(vm.checkVatTimeout) {
        clearTimeout(vm.checkVatTimeout);
      }

      if(vm.orderForm.is_private_person == "false") {
        vm.checkVatTimeout = setTimeout(() => {
          vm.beforeGetPricingData();
          vm.validateVatNumber();
        }, 2000);
      }
    },
    'orderForm.is_private_person': function() {
      this.beforeGetPricingData();
      this.trackIfPrivatePerson();
    },
    'orderForm.street': function() {
      this.validateStreet();
    },
    'orderForm.house_number': function() {
      this.validateHouseNumber();
    },
    'orderForm.address': function() {
      this.validateAddress();
    },
    'orderForm.zipcode': function() {
      this.validateZipCode();
    },
    'orderForm.city': function() {
      this.validateCity();
    },
    'orderForm.state': function() {
      this.getSaleability();
      this.validateState();
    },
    'orderForm.phone': function() {
      this.validatePhone();
    },
    'paymentMethod': function () {
      if(this.paymentMethod == constants.SEQURA && !this.isPhoneRequired) { this.setPhoneCode(true); }
      this.orderFormErrors.payment_method = null;
    },
    'orderForm.promocode_id' : function() {
      this.beforeGetPricingData();
    },
    'orderForm.products_quantity': function(val) {
      if(val < 0) { this.orderForm.products_quantity = Math.abs(val); }
      this.beforeGetPricingData();
      this.recalculateTotalAmounts();
      this.setFrontendProductQuantityForAddons();
    },
    'twoStepsCheckoutInfo': function() {
      if (this.twoStepsCheckoutInfo.address_details && this.twoStepsCheckoutInfo.address_details.length > 0) {
        this.orderForm.address_details = this.twoStepsCheckoutInfo.address_details;
      }
      if (this.twoStepsCheckoutInfo.street && this.twoStepsCheckoutInfo.street.length > 0) {
        this.orderForm.street = this.twoStepsCheckoutInfo.street;
      }
      if (this.twoStepsCheckoutInfo.house_number && this.twoStepsCheckoutInfo.house_number.length > 0) {
        this.orderForm.house_number = this.twoStepsCheckoutInfo.house_number;
      }
      if (this.twoStepsCheckoutInfo.city && this.twoStepsCheckoutInfo.city.length > 0) {
        this.orderForm.city = this.twoStepsCheckoutInfo.city;
      }
      if (this.twoStepsCheckoutInfo.state && this.twoStepsCheckoutInfo.state.length > 0) {
        this.orderForm.state = this.twoStepsCheckoutInfo.state;
      }
      if (this.twoStepsCheckoutInfo.zipcode && this.twoStepsCheckoutInfo.zipcode.length > 0) {
        this.orderForm.zipcode = this.twoStepsCheckoutInfo.zipcode;
      }

      if (this.twoStepsCheckoutInfo.phone && this.twoStepsCheckoutInfo.phone.length > 0) {
        this.orderForm.phone = this.twoStepsCheckoutInfo.phone;
      }
    },
    'addons': {
      handler: function (val, old_val) {
        this.recalculateTotalAmounts();
        this.setPaypalVisibilityForAddons();
        this.setIsInvoiceNeeded();
      },
      deep: true,
      immediate: true,
    },
    currentPaymentPlan: function() {
      this.beforeGetPricingData();
    },
  }
}
