<template>
  <div id="sepa_line_one_popup" :class='{"mod-open" : popupActive, "dc-modal" : true}'>
    <div class="dc-modal--overlay" @click="closePopup"></div>
    <div class="dc-modal--wrapper mod-small">
      <div class="dc-modal--container">
        <div class="dc-modal--header">
          <a class="dc-modal--close" @click="closePopup">
            <em class="zmdi zmdi-close"></em>
          </a>
          <h2 class="mod-xsmall-indent">
            {{title}}
          </h2>
        </div>
        <div class="dc-modal--body">
          <div class="dc-description-block">
            {{message}}
          </div>
          <br>
          <div class="dc-form-group" :class="{ 'mod-group-error': sepaLineOneError }">
            <input class="dc-form-control" type='text' v-model="sepaLineOne" :placeholder="inputPlaceholder()" autocomplete="off" :disabled="!popupActive">
          </div>
        </div>
        <div class="dc-modal--footer">
          <div class="dc-btns-group mod-flex-end">
            <button class="dc-btn mod-success" :class='{ "mod-danger" : danger }' @click.prevent="processConfirmAction">
              {{confirm_btn}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  module.exports = {
    props: {
      title: String,
      message: String,
      confirm_btn: String,
      show: Boolean,
      danger: Boolean,
      is_private: Boolean,
      private_person_message: String,
      company_message: String
    },
    data() {
      return {
        popupActive: false,
        sepaLineOne: '',
        sepaLineOneError: false,
        inProgress: false
      }
    },
    methods: {
      closePopup() {
        this.$emit('on_close');
      },
      processConfirmAction() {
        if (this.inProgress) { return; }

        this.inProgress = true;

        if (this.sepaLineOne.length <= 0) {
          this.inProgress = false;
          return this.sepaLineOneError = true;
        } else {
          this.$emit('on_confirm');
          return this.popupActive = false;
        }
      },
      inputPlaceholder() {
        return this.is_private ? this.private_person_message : this.company_message;
      }
    },
    watch: {
      show: function(val) {
        this.popupActive = val;
      },
      sepaLineOne: function() {
        this.$emit('on_sepa_line_one_updated', this.sepaLineOne);
      }
    }
  }
</script>
